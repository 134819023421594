.layout-background {
    background-color: #fff;
    text-align: center;
}

.footer {
    text-align: center;
}

.footer__content {
    max-width: 320px;
    margin: 24px auto;
    display: flex;
    justify-content: space-between;
}

.footer__content__item > img {
    max-width: 50px;
}
